import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import VerifyPasswordModal from '../modals/auth/transactions/VerifyPasswordModal'
import { Form, Formik } from 'formik'
import { vaultTransactionValiation } from './schema'
import useVaultAction from './hooks/useVaultAction'
import { WALLET_ACTION } from '../../utils/constants'
import TextField from '../form-elements/TextField/TextField'
import SelectField from '../form-elements/SelectField/SelectField'
import Button from '../form-elements/Button/Button'
import { formatNumber } from '../../utils/helpers'
const initialVal = {
  amount: '',
  currency: ''
}
const VaultDepositWithdraw = () => {
  const { t } = useTranslation()
  const {
    showAccNumber,
    verifyUserLoading,
    isPasswordModalOpen,
    setIsPasswordModalOpen,
    handleWalletAction,
    handleSubmit,
    data,
    vaultActionLoading,
    actionType,
    setActionType,
    userDetails
  } = useVaultAction()
  const [form, setForm] = useState(initialVal)
  useEffect(() => {
    setForm(initialVal)
  }, [actionType])

  const renderForm = (type) => {
    return (
      <div className='deposit-withdraw'>
        <Formik
          initialValues={form}
          validationSchema={vaultTransactionValiation(t, type === WALLET_ACTION.DEPOSIT ? userDetails?.userWallet?.totalAmount : userDetails?.userWallet?.vaultAmount)}
          onSubmit={(values) => handleWalletAction(type, values)}
          validateOnChange
          validateOnBlur
          enableReinitialize
          key={type}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched

          }) => {
            return (
              <Form className=' signup-form1 d-flex flex-column gap-3'>
                <div className=' deposit-withdraw--parent  '>
                  <div className=' d-flex  flex-column input-label'>
                    <div className='form-group'>
                      <SelectField
                        label={t('currency')}
                        name='currency'
                        value={values.currency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        firstOption={
                          <option value='' disabled>
                            {t('selectCurrency')}
                          </option>
                        }
                        options={[
                          { label: 'KRW', value: 'KRW' }
                        ]}
                      />
                    </div>
                  </div>
                  <div>
                    <TextField
                      isCurrencyField
                      label={t('amount')}
                      name='amount'
                      value={values.amount}
                      onChange={(val) => {
                        setFieldValue('amount', val)
                      }}
                      onBlur={handleBlur}
                      placeholder={t('enterAmountPlaceholder')}
                    />
                  </div>
                  <div style={{ fontSize: 14 }} className='d-flex align-items-center amount-show-wrapper gap-2 pb-2 pt-3'>

                    <div>
                      {type === WALLET_ACTION.DEPOSIT ? t('depositableAmount') : t('withdrawableAmount')}:
                    </div>
                    <div>
                      {type === WALLET_ACTION.DEPOSIT ? formatNumber(userDetails?.userWallet?.totalAmount || 0) : formatNumber(userDetails?.userWallet?.vaultAmount || 0)} {userDetails?.currencyCode}
                    </div>

                  </div>
                </div>
                <div className='footer-modal'>
                  <ul>

                    <li>
                      <Button variant='primary' loading={vaultActionLoading} title={vaultActionLoading ? t('loading') : type === WALLET_ACTION.WITHDRAW ? t('requestWithdraw') : t('requestDeposit')} type='submit' />

                    </li>
                  </ul>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
  const memoiZedForm = useMemo(() => renderForm(actionType), [actionType, showAccNumber, vaultActionLoading, t])
  return (
    <>

      <div>
        <ul className='nav nav-pills d-flex  m-auto list-unstyled ' id='pills-tab' role='tablist'>
          <li className='nav-item' role='presentation'>
            <button onClick={() => setActionType(WALLET_ACTION.DEPOSIT)} className='nav-link active' id='pills-deposit-tab' data-bs-toggle='pill' data-bs-target='#pills-deposit' type='button' role='tab' aria-controls='pills-deposit' aria-selected='true'>{t('deposit')}</button>
          </li>
          <li className='nav-item' role='presentation'>
            <button onClick={() => setActionType(WALLET_ACTION.WITHDRAW)} className='nav-link' id='pills-withdraw-tab' data-bs-toggle='pill' data-bs-target='#pills-withdraw' type='button' role='tab' aria-controls='pills-withdraw' aria-selected='false'>{t('withdraw')}</button>
          </li>
        </ul>
        <div className='tab-content' id='pills-tabContent'>
          <div className='tab-pane fade show active' id='pills-deposit' role='tabpanel' aria-labelledby='pills-deposit-tab'>
            {memoiZedForm}
          </div>
          <div className='tab-pane fade' id='pills-withdraw' role='tabpanel' aria-labelledby='pills-withdraw-tab'>
            {memoiZedForm}
          </div>
        </div>
      </div>
      <VerifyPasswordModal
        type={data?.actionType}
        isOpen={isPasswordModalOpen}
        handleClose={() => setIsPasswordModalOpen(false)}
        onSubmit={handleSubmit}
        loading={verifyUserLoading}
      />
    </>
  )
}

export default VaultDepositWithdraw
