import React, { Suspense } from 'react'
import { ScrollToTop } from '../utils/helpers'
import { CookieModal } from '../components/CookieModal'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from 'react-router-dom'
import Loader from '../components/Loader'

import {
  casinoRoutesList,
  privateRoutesList,
  publicRoutesList,
  sportsRoutesList
} from './RoutesList'
import { Routes as pathRoutes } from '../utils/routes'
import RenderModal from './RenderModal'
const Sportsbook = React.lazy(() => import('../pages/Sportsbook'))
const NotFound = React.lazy(() => import('../pages/NotFound'))
const PrivateRoute = React.lazy(() => import('../components/PrivateRoute'))
const Comingsoon = React.lazy(() => import('../components/ComingSoon/ComingSoon'))
const Layout = React.lazy(() => import('../components/Layout'))
const Home = React.lazy(() => import('../pages/Home'))
const Maintenance = React.lazy(() => import('../pages/Maintenance/Maintenance'))

const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <CookieModal />
      <RenderModal />
      <Suspense fallback={<Loader type='screen' />}>
        <Routes>
          <Route path='/maintenance' element={<Maintenance />} />
          <Route path={pathRoutes.homepage} element={<Layout />}>
            <Route index element={<Home />} />

            {/* public routes */}
            {publicRoutesList.map((route) => (
              <Route
                key={route.ComponentName}
                path={route.path}
                element={<PrivateRoute> {route.element}</PrivateRoute>}
              />
            ))}

            {/* private routes */}
            {privateRoutesList.map((route) => (
              <Route
                key={route.ComponentName}
                path={route.path}
                element={<PrivateRoute isPrivateRoute> {route.element}</PrivateRoute>}
              />
            ))}

            {/* Casino routes */}
            <Route
              path={pathRoutes.casino}
              element={<Outlet />}
            >

              {/* Casino sub-routes */}
              {casinoRoutesList.map((route) => (
                <Route
                  key={route.ComponentName}
                  path={route.path}
                  element={<PrivateRoute isPrivateRoute={route?.isPrivateRoute}> {route.element}</PrivateRoute>}
                />

              ))}
              <Route
                path='*'
                element={
                  <NotFound />
              }
              />
            </Route>

            {/* sportsbook routes */}
            <Route
              path={pathRoutes.sportsbook}
              element={<Outlet />}
            >
              <Route index element={<Sportsbook />} />
              {sportsRoutesList.map((route) => (
                <Route
                  key={route.ComponentName}
                  path={route.path}
                  element={<PrivateRoute isPrivateRoute={route?.isPrivateRoute}> {route.element}</PrivateRoute>}
                />

              ))}
              <Route
                path='*'
                element={
                  <Comingsoon />
              }
              />
            </Route>
            <Route
              path='*'
              element={
                <NotFound />
              }
            />
          </Route>
        </Routes>
      </Suspense>

    </Router>
  )
}

export default AppRoutes
