const ENJSON = {
  translation: {
    claimed: 'Claimed',
    redTiger: 'Red Tiger',
    netEnt: ' NetEnt',
    bigTimeGaming: 'Big Time Gaming',
    nolimitCity: ' Nolimit City',
    sorryForInconvenience: 'Sorry for the inconveniences.',
    maintenaceModeOn: 'Maintenance mode is on',
    maintenaceContent: 'Site will be available soon. Thank you for your patience',

    wagered: 'Wagered',
    betAmount: 'Bet Amount',
    betSlip: 'Bet Slip',
    referralCodeInvalid: 'Invalid referral code',
    transactions: 'Transactions',
    rollBonus: 'Roll Bonus',
    rollBonusText: 'Are you sure you want to roll bonus amount into cash amount?',
    liveSupport: 'Live Support',
    from: 'From',
    to: 'To',
    selectDate: 'Select Date',

    rollingContest: 'Rolling Contest',
    event: 'Event',
    bigWin: 'Big Win',
    referralCodeRequired: 'Referral Code Required',
    home: 'Home',
    referralCodePlaceholder: 'Enter a referral code',
    theme: 'Theme',
    light: 'Light',
    dark: 'Dark',
    chooseImage: 'Choose Image',
    imageFormatRequired: 'Image must be of the format type jpg,png,webp',
    imageSize5MB: 'Image must be within 5Mb size',
    profileImageUploaded: 'Profile image uploaded successfully',
    profileImageRemoved: 'Profile image removed successfully',
    allContents: 'All Contents',
    responsibleGaming: 'Responsible Gambling',
    tenanatAreBlocked: 'Tenant is blocked',
    cashAmount: 'Cash Amount',
    EMERGENCY: 'Emergency',
    MAINTENANCE: 'Maintenance',
    Bonus: 'Bonus',
    WALLET: 'Wallet',
    'UNIQUE-NUMBER-BONUS': 'Unique Number Bonus',
    LIVE: 'LIVE',
    LOSE: 'LOSE',
    playing: 'playing',
    noBonusComingSoon: 'Coming Soon, Not started yet',
    noBonusFound: 'No bonus found',
    bonusTickets: 'Bonus Tickets',
    couponNumber: 'Coupon Number',
    totalTickets: 'Total Tickets',
    nonCashAmount: 'Promotion Cash Wallet',
    noNotificationFound: 'No Notifications Available',
    notificationTitle: 'Notifications',
    total: 'Total',
    vaultAmount: 'Vault Amount',
    vault: 'Vault',
    vaultDepositSuccess: 'Deposit Successfully',
    vaultWithdrawSuccess: 'Withdraw Successfully ',
    LOST: 'LOST',
    BET: 'BET',
    WIN: 'WIN',
    blacklistedError: 'You are blacklisted, Not allowed to login',
    submit: 'Submit',
    Active: 'Active',
    InActive: 'InActive',
    date: 'Date',
    recommendGames: 'Recommend Games',
    noProvidersFound: 'No Providers Found',
    rank: 'Rank',
    username: 'Username',
    createdAt: 'Created At',
    status: 'Status',
    copy: 'Copy',
    cancel: 'Cancel',
    confirm: 'Confirm',
    copied: 'Copied',
    copiedSuccess: 'Copied successfully',
    referredUsers: 'Referred Users',
    referAUser: 'Refer a User',
    referByLink: 'Refer a user by link',
    noReferralUsers: 'No Referred Users Found',
    kycNotComplete: 'Your KYC is not completed, Please verify first to access the platform',
    checkKycStatus: 'KYC Status',
    kycStatusTitle: 'KYC STATUS',
    verified: 'Verified',
    notVerified: 'Not Verified',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    action: 'Action',
    otherLinks: 'Other Links',
    landingPage: 'Landing Page',
    supportLinks: 'Support Links',
    others: 'Others',
    casinoBets: 'Casino Bets',
    sportBets: 'Sports Bets',
    insufficientBalance: 'Insufficient Balance !',
    record: 'Records',
    data: 'Data',
    oldPasswordDidNotMatch: "Old password doesn't match",
    loading: 'Loading...',
    submitting: 'Submitting...',
    hide: 'Hide',
    request: 'Requests',
    clearAll: 'Clear All',
    noFavouritesGames: 'No games added to your favourite list.',
    withdrawlPasswordDoesNotMatch: 'Withdrawl Password Does Not Match',
    na: 'N/A',
    ACCEPT: 'Accepted',
    PENDING: 'Pending',
    REJECT: 'Rejected',
    withdrawal: 'Withdrawal',
    prize: 'Prize',
    noDeposit: 'No Deposits',
    noWithdrawl: 'No Withdrawal',

    logoutContent: 'Don’t forget to check out our huge selection of casino and sports promotions before you leave!',
    verifyUser: 'Verify User',
    currencyRequired: 'Currency is Required',
    amountRequired: 'Amount is Required',
    selectCurrency: 'Select Currency',
    currency: 'Currency',
    requestCreatedSuccess: 'Request Created Successfully',
    walletActionSourceAlreadyExists: 'Pending Request Already Exists',
    min1Required: 'Minimum 1 is Required',
    positiveNumber: 'Positive number allowed only',

    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    hidden: 'Hidden',
    show: 'Show',
    amount: 'Amount',
    enterAmountPlaceholder: 'Enter amount',
    requestWithdraw: 'Request Withdraw',
    requestDeposit: 'Request Deposit',
    language: 'Language',
    odds: 'Odds',
    decimal: 'Decimal',
    fractional: 'Fractional',
    support: 'Support',
    partners: 'Partners',
    press: 'Press',
    allRightsReserved: 'All Rights Reserved',
    acceptTerms: 'I accept the terms and conditions',
    termsAndConditon: 'Terms and Condition',
    footerContent: 'Dino is owned and operated by Medium Rare N.V., registration number: 145353, registered address: Korporaalweg 10, Willemstad, Curaçao. Contact us at support@dino.com. Payment agent company is Medium Rare Limited with address 7-9 Riga Feraiou, LIZANTIA COURT, Office 310, Agioi Omologites, 1087 Nicosia, Cyprus and Registration number: HE 410775',
    game: 'Game',
    menu: 'Menu',
    user: 'User',
    time: 'Time',
    hacksaw: 'Hacksaw',
    fullScreen: 'Fullscreen',
    recommended: 'Recommended',
    addToFavourites: 'Add to favourites',
    removeFromFavourites: 'Remove from favourites',
    addToFavSuccess: 'Added to favourites successfully',
    removeFromFavSuccess: 'Removed from favourites successfully',
    enableTheatre: 'Enable theatre mode',
    disableTheatre: 'Disable theatre mode',
    pragmatic: 'Pragmatic Play',
    evolution: 'Evolution',
    pgsoft: 'PG Soft',
    payout: 'Payout',
    viewAllProvider: 'View All Provider',
    loadMore: 'Load More',
    sortBy: 'Sort By',
    filterBy: 'Filter By',
    atoz: 'A - Z',
    ztoa: 'Z - A',
    all: 'All',
    viewAll: 'View All',
    save: 'Save',
    areYouSure: 'Are You Sure',
    of: 'of',

    // headings
    unbeatableDeal: ' Unbeatable Deals Inside',
    limitedTimeOffer: ' Limited Time Offer',
    biggestSale: 'Biggest Sale Ever!',
    grabYourSaving: 'Grab Your Savings',
    newYearDeal: ' New Year, New Deals!',
    exploreNow: ' Explore Now',
    claimNow: ' Claim Now',
    scratchcard: 'Scratchcard',
    slot: 'Slot',
    blackjack: 'BlackJack',
    'live games': 'Live Casino',
    baccarat: 'Baccarat',
    hot: 'Hot',
    new: 'New',
    roulette: 'Roulette',
    unknown: 'Unknown',

    // title and links
    logout: 'Logout',
    wallet: 'Wallet',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    setting: 'Setting',
    changePassword: 'Change Password',
    search: 'Search',
    login: 'LogIn',
    casino: 'Casino',
    sports: 'Sports',
    favourites: 'Favourites',
    myBets: 'My Bets Casino',
    mySportsBets: 'My Bets Sportsbook',
    allBets: 'Total bet Leaderboard',
    raceLeaderBoard: 'Big win Leaderboard',
    raceRollingContestBoard: 'Rolling Contest leader board',
    games: 'Games',
    searchYourGame: 'Search Your Game',
    searchAtleast3Char: 'Search requires at least 2 characters.',
    providers: 'Providers',
    forgotPassword: 'Forgot Password',
    profile: 'Profile',
    sportsBook: 'Sports Book',
    comingSoon: 'Coming Soon',
    goToLobby: 'Go To Lobby',
    goToCasino: 'Go To Casino',
    goToSports: 'Go To Sports',
    casinoTitle: ' Leading Online Crypto Casino',
    noCryptoNoProblem: 'No Crypto? No Problem!',
    buyCrypto: 'Buy Crypto',
    casinoPara: ' Browse our giant range of casino games as Dino offers a fair and fun online gambling experience. Play Slots, Live Casino, Blackjack, Baccarat, Roulette, and thousands of classic casino games right from your browser, including your favourite Dino Originals.',
    sportsPara: 'Bet on your favourite teams, players and leagues from all around the world on our sports betting platform. Gamble on a wide range of sports betting options and markets for live sports across MMA, Basketball, Soccer and more for an unbeatable sports betting experience.',
    sportsTitle: 'Best Crypto Sports Betting Online',
    displaying: 'Displaying',
    errorWhileLoadingGame: 'Error while loading Game',
    hotTimeBonus: 'Hot Time Bonus',
    compensationBonus: 'Compensation Bonus',
    reocurringBonus: 'Reocurring Bonus',
    totalWalletAmount: 'Total Wallet Amount',
    bounsWalletAmount: 'Bouns Wallet Amount',

    // formLabels
    usernameLabel: 'Username',
    passwordLabel: 'Password',
    oldPasswordLabel: 'Old Password',
    newPasswordLabel: 'New Password',
    confirmPasswordLabel: 'Confirm Password',
    confirmNewPasswordLabel: 'Confirm New Password',
    idOfUserLabel: 'ID of User',
    legalNameLabel: 'Legal Name',
    nationalityLabel: 'Nationality',
    dateOfBirthLabel: 'Date of Birth',
    selectMonth: 'Select Month',
    selectDay: 'Select Day',
    selectYear: 'Select Year',
    phoneLabel: 'Phone',
    preferredCurrencyLabel: 'Preferred Currency',
    bankNameLabel: 'Bank Name',
    accountNumberLabel: 'Account Number',
    withdrawalPasswordLabel: 'Withdrawal Password ',
    reEnterwithdrawalPasswordLabel: 'Re-enter Withdrawal password ',

    // SignUp and Account Info
    signIn: 'Sign In',
    signUp: 'Sign Up',
    userIdPlaceholder: 'Enter user Id',
    passwordRequired: 'Password Required',
    oldPasswordRequired: 'Old Password Required',
    newPasswordRequired: 'New Password Required',
    accountFrozen: 'Your account is frozen till',
    userLoggedOut: 'Logged Out Successfully',
    loggingIn: 'Submitting please wait...',
    selectNationality: 'Select Nationality',
    selectBankName: 'Select Bank Name',
    selectPreferredCurrency: 'Select Preferred Currency',
    dontHaveAccount: ' Don’t have an account?',
    registerAnAccount: '  Register an Account',
    alreadyHaveAnAccount: 'Already have an account?',
    fillAllFields: 'Please fill out all required fields before signing up.',
    userNamePlaceholder: 'Enter Username',
    min8Numbers: 'Min 8 numbers required',
    passwordValidationMessage: 'Password must have at least 8 characters with at least one uppercase letter, one number and one special character. Spaces, dots and comma not allowed',
    withdrawlPasswordValidationMessage: 'Withdrawal password must have at least 8 characters with at least one uppercase letter, one number and one special character. Spaces, dots and comma not allowed',
    passwordPlaceholder: 'Enter Password',
    confirmPasswordPlaceholder: 'Enter Confirm Password',
    passwordWithdrawalPlaceholder: 'Enter Withdrawal Password',
    oldPasswordPlaceholder: 'Enter Old Password',
    newPasswordPlaceholder: 'Enter New Password',
    confirmNewPasswordPlaceholder: 'Enter Confirm New Password',
    confirmPasswordWithdrawalPlaceholder: 'Re Enter Withdrawal Password',
    legalNamePlaceholder: 'Enter Legal Name',
    phonePlaceholder: 'Enter Phone Number',
    searchPhonePlaceholder: 'Search Country',
    accountNumberPlaceholder: 'Enter Account Number',
    userIdRequired: 'User Id Required',
    userIdInvalid: 'Only alphanumeric is accepted',
    confirmNewPasswordRequired: 'Confirm New Password Required',
    confirmPasswordRequired: 'Confirm Password Required',
    passwordWithdrawalRequired: 'Withdrawal Password Required',
    confirmPasswordWithdrawalRequired: 'Confirm Withdrawal Password Required',
    legalNameRequired: 'Legal Name Required',
    phoneRequired: 'Phone Required',
    dateOfBirthRequired: 'Date Of Birth Required',
    userNameRequired: 'User Name Required',
    requiredAccountNumber: 'Account Number Required',
    countryRequired: 'Country Required',
    matchPassword: 'Password must match',
    matchWithdrawalPassword: 'Withdrawal Password must match',
    onlyAlphabet: 'Only Alphabets allowed',
    mustBe18: 'You Must be 18 Year old to get registered',
    validDOB: 'Please enter a valid Date of Birth',
    max20Characters: 'Maximum 20 Characters Allowed',
    min3Characters: 'Minimum 3 Characters Required',
    min2Characters: 'Minimum 2 Characters Required',
    min4Characters: 'Minimum 4 Characters Required',
    min5Numbers: 'Minimum 5 digits required including dial code',
    spaceNotAllowed: 'Spaces, dots and comma not allowed',
    onlySpaceNotAllowed: 'Spaces not allowed',
    max100Characters: 'Maximum 100 Characters Allowed',
    max50Characters: 'Maximum 50 Characters Allowed',
    max30Characters: 'Maximum 30 Characters Allowed',
    max16Characters: 'Maximum 16 Characters Allowed',
    max16Numbers: 'Maximum 16 digits allowed including dial code',
    preferredLanguageRequired: 'Preferred Language Required',
    preferredCurrencyRequired: 'Preferred Currency Required',
    bankNameRequired: 'Bank Name Required',
    nationalityRequired: 'Nationality Required',
    legalName: 'Alphabets and spaces allowed only',
    legalNameOneSpace: 'Only one spaces allowed between words',
    selectPreferredLanguage: 'Select Preferred Language',
    enterValidPhone: 'Please enter valid phone number',
    userNameAllowed: 'Lowercase alphabets allowed and no spaces allowed.',
    myVouchers: 'My Vouchers',
    noVoucherFound: 'No vouchers available',
    voucherName: 'Voucher Name',
    validFrom: 'Valid From',
    validTo: 'Valid To',
    betTarget: 'Bet Target',
    voucherUseLimit: 'Voucher Use Limit',
    redeem: 'Redeem',
    vouchers: 'Vouchers',
    remainingRedeemptions: 'Remaining Redeemptions',

    // error
    userNameAlreadyExists: 'Username already exists!',
    incorrectCredentials: 'Username with given password does not exist!',
    UserCountryCodeWithPhoneAlreadyExists: 'Username with this phone already exists!',
    loginErrorMessage: 'Please Login to Continue',
    // Not Found
    pageNotFound: 'Page Not Found',
    suggestToBackHome: 'We suggest you back to home',
    noGames: 'No games found',
    noEvents: 'Matches not Available',
    oopps: 'Oopps',
    somethingNotWorking: 'We’re sorry, something is not working here!',

    // ErrorMessages
    noDataFound: 'No Data Found',
    noCategory: 'No category found',

    // Reset/Forgot Password
    setNewPass: 'Set a new password',

    // Toast
    loginSuccess: 'Login Successful',
    signupSuccess: 'Sign Up Successful',
    updatedSuccess: 'Updated Successfully',

    // Cookie Consent
    cookieTitle: 'Cookies!',
    declineButton: 'Decline',
    acceptButton: 'Accept',
    cookieDeclinedMessage: 'For the best possible experience, cookies must be accepted to continue using this website. Please accept the cookies if you want to proceed.',
    cookieDescription: 'Welcome to dino.com! We use cookies to optimise your user experience. By clicking \'Accept,\' you agree to their use. You can opt-out by clicking \'Decline.',
    forgetPasswordRequest: 'Forget Password?',
    forgetPassword: 'Forget Password',
    forgetPasswordRequestSent: 'Forget Password Request Sent!!',
    itWillbeYourUpdatedWithdrawalPasswordAsWell: 'Please note that this will also serve as your updated withdrawal password.',
    newLevelUnlock: 'New Level Unlock',
    newSeasonUnlock: 'New Season Unlock',
    vouchersAndGiftcards: 'Vouchers and Gift Cards',
    giftCards: 'Gift Cards',
    availableRedeemptions: 'Available Redeemptions',
    noGiftCardFound: 'No gift cards available',
    joinContest: 'Join Contest',
    contestName: 'Contest Name',
    joincontestContent: 'Bet your wits, win your fate – enter if you dare!',
    yes: 'Yes',
    no: 'No',
    registeredForRollingContest: "Locked and loaded for the games! You're officially registered for the excitement!",
    alreadyRegister: 'You are already registered!!',
    noContestSchedule: 'No Contest Scheduled',
    playerName: 'Player Name',
    leadAmount: 'Lead Amount',

    // ********** SportsBook Translations ********************
    noHotComboAvailable: 'Hot Combos are not available at this moment',
    pleaseEnterStake: 'Please enter a stake',
    single: 'Single',
    combo: 'Combo',
    noBetsAdded: 'No bets added',
    possibleWin: 'Possible Win',
    placeBet: 'Place Bet',
    clearBetslip: 'Clear Betslip',
    vs: 'VS',
    liveEvents: 'Live Events',
    topEvents: 'Top Events',
    upcomingEvents: 'Upcoming Events',
    highlight: 'Highlight',
    schedule: 'Schedule',
    live: 'Live',
    favorite: 'Favorite',
    myBet: 'My Bet',
    result: 'Result',
    prev: 'Prev',
    next: 'Next',
    today: 'Today',
    tomorrow: 'Tomorrow',
    favorites: 'Favorites',
    selectSport: 'Select Sport',
    selectCountry: 'Select Country',
    selectLeague: 'Select League',
    fromDate: 'From Date',
    toDate: 'To Date',
    winAmount: 'Win Amount',
    stake: 'Stake',
    lostAmount: 'Loss Amount',
    cashoutAmount: 'Cashout Amount',
    pending: 'Pending',
    rejected: 'Rejected',
    cashoutButton: 'Cashout',
    betSlipId: 'Betslip ID',
    in_game: 'In Game',
    cashout: 'Cash Out',
    won: 'Won',
    lost: 'Lost',
    refund: 'Refunded',
    partial_won: 'Partial Won',
    topLeague: 'Top Leagues',
    noLeagues: 'Leagues not Available',
    alternativeOddType: 'Alternative Odd Type',
    areYouSureCashout: ' Are you sure you want to cashout?',
    alternateStakeButton: 'Alternative Stake',
    reOfferButton: 'Bet Reoffer',
    alternativeStakeTitle: 'Alternative Stake Offer',
    viewAllMarket: 'Click here to view all market',
    closed: 'Closed',
    removeClosedBet: 'Please remove closed bet form betslip!',
    betSetting: 'Odds Setting',
    any: 'Accept any odds changes',
    none: "Don't accept odds changes",
    higher: 'Accept higher odds changes',
    reset: 'Reset',
    selectFromDate: 'Select From Date',
    selectToDate: 'Select To Date',
    customBet: 'Custom Bet',
    calclutedOds: 'Calculated Odd',
    removeMainBet: 'Please remove main bets first from betslip',
    removeCustomBet: 'Please remove custom bets first from betslip',
    addSameCusomMarket: 'Please add same custom market events',
    enterStake: 'Please enter stake',
    main: 'Main',
    custom: 'Custom',
    quickBet: 'Quick Bet',
    quickBetDesc: 'QuickBet mode is on! After single click on any selection, it will place your bet immediately. See all your bets on',
    page: 'page',
    quickBetIsActiveWith: 'Quick Bet is active with',
    pleaseEnterStakeToActiveBet: ' Please enter stake to activate the bet',
    loginToPlaceBet: 'Please login to place bet',
    habanero: 'Habanero',
    tie: 'Tie',
    addAtleasetTwoEvents: 'Add minimum two custom events to place bet',
    events: 'Events',
    noEventsSchedule: 'No events scheduled',
    bonus: 'Bonus',
    reoccuringBonus: 'Reoccuring bonus',
    firstDepositBonus: 'First Deposit Bonus',
    noBonus: 'No bonus',
    bonusLapsed: 'Bonus Lapsed',
    bonusRollProgress: 'Bonus Roll Progress',
    'HOT-TIME-BONUS': 'HOT-TIME-BONUS',
    'COMPENSATION-BONUS': 'COMPENSATION-BONUS',
    'RE-OCCURING-DEPOSIT-BONUS': 'RE-OCCURING-DEPOSIT-BONUS',
    'FIRST-DEPOSIT-BONUS': 'FIRST-DEPOSIT-BONUS',
    'UNIQUE-TICKET-BONUS': 'UNIQUE-TICKET-BONUS',
    notActive: 'Not Active',
    active: 'Active',
    depositMoneyGetBonusBenefits: 'Deposit money to get bonus benefits',
    bonusDetails: 'Bonus Details',
    gamble: 'Gamble',
    poker: 'Poker',
    refresh: 'Refresh',
    somethingWentWrong: 'Something went wrong. Please reload ',
    season: 'Season',
    unlocked: 'Unlocked',
    activate: 'Activate',
    pause: 'Pause',
    activateSuccess: 'Activated Successfully',
    pauseSuccess: 'Paused Successfully',
    redeemSuccess: 'Redeem Successfully',
    betPlaced: 'Bet Placed',
    betPlacedSuccess: 'Bet is being processed, Please check the notification for more info',
    viewProgress: 'View Progress',
    inPlay: 'In Play',
    dinoCasinoLeaderboard: 'Dino Casino Leaderboard',
    welcomeToDinoCasino: 'Welcome to Dino Casino!',
    registerYourAccount: 'Register your account!',
    myLevelProgress: 'My Level Progress',
    levelStatus: 'Level Status',
    notStarted: 'Match is not started yet.',
    matchLive: 'Match is live',
    matchSuspended: 'Match is suspended',
    ended: 'Match is finished',
    matchClosed: 'Match is closed',
    marketActive: 'Market is active',
    marketDeactive: 'Market is deactivated',
    marketSuspended: 'Market is suspended',
    marketSettled: 'Market is settled',
    marketCancelled: 'Market is cancelled',
    outcomeDeactive: 'Outcome is deactivated',
    outcomeActive: 'Outcome is active',
    outcomeSettled: 'Outcome is settled',
    outcomeSuspended: 'Outcome is suspended',
    outcomeCancelled: 'Outcome is cancelled',
    balance: 'Balance',
    remaining: 'Remaining',
    referral: 'Referral',
    half_won: 'Half Won',
    half_lost: 'Half Lost',
    achievements: 'Achievements',
    extendednetent: 'Extended Netent',
    depositWallet: 'Deposit',
    withdrawalWallet: 'Withdraw',
    level: 'Level',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
    dinoCasino: 'Dino Casino,',
    majorHiddenProgress: 'major hidden update in progress.',
    experienceSoon: 'You’ll be able to experience it soon!',
    depositableAmount: 'Depositable amount',
    withdrawableAmount: 'Withdrawable amount',
    cashoutMessage: 'Cashout inprogress, Please check notification for more info',
    cashoutInprogress: 'Cashout Inprogress',
    favoriteRemoved: 'Favorite removed successfully',
    favoriteAdded: 'Favorite added successfully',
    maxCustomMarketLimit: 'You can select max 10 custom market conbination in single bet slip',
    betErrorHotCombo: 'Bet can not be place for this hot combo, try with different hot combo',
    paybackBonus: 'Payback Bonus',
    'PAYBACK-BONUS': 'PAYBACK-BONUS',
    transactionDeposit: 'Deposit',
    transactionWithdrawal: 'Withdrawal',
    mustBeMultipleOf10000: 'The amount must be a multiple of 10,000.',
    min10000Required: 'The amount must be greater than or equal to 10,000',
    maxAmountEnteredis: 'Maximum amount is',
    requestErrorMessage: 'The withdrawal request amount must be less than or equal to your cash wallet balance'

  }
}

export default ENJSON
