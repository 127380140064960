import * as Yup from 'yup'
import { WALLET_ACTION } from '../../utils/constants'
import { formatNumber } from '../../utils/helpers'

// Schema for EN with Extra Validations
export const transactionValiation = (t, actionType, amount) =>
  Yup.object().shape({
    amount: WALLET_ACTION.WITHDRAW === actionType
      ? Yup.number()
          .positive(t('positiveNumber'))
          .min(10000, t('min10000Required'))
          .max(amount, `${t('maxAmountEnteredis')} ${formatNumber(amount)} (${t('requestErrorMessage')})`)
          .required(t('amountRequired'))
          .test('is-multiple-of-10000', t('mustBeMultipleOf10000'), value => {
            return value % 10000 === 0
          })
      : Yup.number()
        .positive(t('positiveNumber'))
        .min(10000, t('min10000Required'))
        .required(t('amountRequired'))
        .test('is-multiple-of-10000', t('mustBeMultipleOf10000'), value => {
          return value % 10000 === 0
        }),
    currency:
        Yup.string()
          .required(t('currencyRequired'))

  })

export const vaultTransactionValiation = (t, amount) =>
  Yup.object().shape({
    amount:
      Yup.number().positive(t('positiveNumber'))
        .max(amount, `${t('maxAmountEnteredis')} ${formatNumber(amount)}`)
        .min(1, t('min1Required'))
        .required(t('amountRequired')),
    currency:
      Yup.string()
        .required(t('currencyRequired'))

  })
