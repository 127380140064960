import { takeLatest, put, call, select } from 'redux-saga/effects'
import { t } from 'i18next'

import {
  getSportsGamesCategory, getSports, getLeagues, getEvents, getMarketCategories, getTopLeagues, getTopEvents, getResults, getSingleEvent, getFavorites, getCountries, addFavorites, placeBet, getMarkets, getAllBets, getBetDetails, getCashoutAmount, processCashout,
  betReoffer,
  alternativeStake,
  calculateOds,
  getCustomEvents,
  getHotCombos
} from '../../utils/sportsApiCalls'

import {
  getSportsGameCategoriesStart,
  getSportsGameCategoriesComplete,
  getSportsGameCategoriesFailure,
  getSportsStart,
  getSportsComplete,
  getSportsFailure,
  getLeagueStart,
  getLeagueComplete,
  getLeagueFailure,
  getEventStart,
  getEventComplete,
  getEventFailure,
  getMarketCategoryStart,
  getMarketCategoryComplete,
  getMarketCategoryFailure,
  getTopLeaguesStart,
  getTopLeaguesComplete,
  getTopLeaguesFailure,
  getTopEventsStart,
  getTopEventsComplete,
  getTopEventsFailure,
  getResultsStart,
  getResultsComplete,
  getResultsFailure,
  getSingleEventStart,
  getSingleEventComplete,
  getSingleEventFailure,
  getLiveFavStart,
  getLiveFavComplete,
  getLiveFavFailure,
  getUpcomingFavStart,
  getUpcomingFavComplete,
  getUpcomingFavFailure,
  getCountriesComplete,
  getCountriesFailure,
  getCountriesStart,
  toggleFavoriteFailure,
  toggleFavoriteStart,
  toggleFavoriteSuccess,
  getLiveEventStart,
  getLiveEventComplete,
  getLiveEventFailure,
  placeBetStart,
  placeBetComplete,
  getEventMarketsStart,
  getEventMarketsComplete,
  getEventMarketsFailure,
  getMyBetsStart,
  getMyBetsComplete,
  getMyBetsFailure,
  getBetDetailsStart,
  getBetDetailsComplete,
  getBetDetailsFailure,
  getCashoutAmountStart,
  getCashoutAmountComplete,
  getCashoutAmountFailure,
  processCashoutStart,
  processCashoutComplete,
  processCashoutFailure,
  reofferBetFailure,
  reofferBetComplete,
  reofferBetStart,
  alternativeStakeFailure,
  alternativeStakeComplete,
  alternativeStakeStart,
  quickBetLoadingStatus,
  getCustomEventsFailure,
  getCustomEventsComplete,
  getCustomEventsStart,
  getCalculatesOdsStart,
  getCalculatesOdsComplete,
  getCalculatesOdsFailure,
  getHotCombosFailure,
  getHotCombosComplete,
  getHotCombosStart,
  setSocketLiveEventData,
  setSocketUpcomingEventData,
  setSocketTopEventData,
  setSocketLiveFavoriteEvents,
  setSocketUpcomingFavoriteEvents,
  setSocketDetailsPageSingleEvents,
  altStakeBetSlipStart,
  altStakeBetSlipSuccess,
  altStakeBetSlipFailure,
  setAltStakePopupOpen
} from '../redux-slices/sports'
import { toast } from '../../components/Toast'
import { getNotificationsSuccess } from '../redux-slices/user'

export default function * sportsWatcher () {
  yield takeLatest(getSportsGameCategoriesStart.type, getSportsGameCategoriesWorker)
  yield takeLatest(placeBetStart.type, placeBetWorker)
  yield takeLatest(getSportsStart.type, getSportsWorker)
  yield takeLatest(getLeagueStart.type, getLeagueWorker)
  yield takeLatest(getEventStart.type, getEventWorker)
  yield takeLatest(getLiveEventStart.type, getLiveEventWorker)
  yield takeLatest(getMarketCategoryStart.type, getMarketCategoryWorker)
  yield takeLatest(getTopLeaguesStart.type, getTopLeaguesWorker)
  yield takeLatest(getTopEventsStart.type, getTopEventsWorker)
  yield takeLatest(getResultsStart.type, getResultsWorker)
  yield takeLatest(getSingleEventStart.type, getSingleEventWorker)
  yield takeLatest(getLiveFavStart.type, getLiveFavWorker)
  yield takeLatest(getUpcomingFavStart.type, getUpcomingFavWorker)
  yield takeLatest(getCountriesStart.type, getCountriesWorker)
  yield takeLatest(toggleFavoriteStart.type, toggleFavoriteWorker)
  yield takeLatest(getEventMarketsStart.type, getEventMarketsWorker)
  yield takeLatest(getMyBetsStart.type, getMyBetsWorker)
  yield takeLatest(getBetDetailsStart.type, getBetDetailsWorker)
  yield takeLatest(getCashoutAmountStart.type, getCashoutAmountWorker)
  yield takeLatest(processCashoutStart.type, processCashoutWorker)
  yield takeLatest(alternativeStakeStart.type, alternativeStakeWorker)
  yield takeLatest(reofferBetStart.type, reofferBetWorker)
  yield takeLatest(getCalculatesOdsStart.type, calculateOdsWorker)
  yield takeLatest(getCustomEventsStart.type, customEventWorker)
  yield takeLatest(getHotCombosStart.type, getHotCombosWorker)
  yield takeLatest(altStakeBetSlipStart.type, getAltStakeBetDetailsWorker)
}

function * getSportsGameCategoriesWorker (action) {
  try {
    const { data } = yield getSportsGamesCategory()
    yield put(getSportsGameCategoriesComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getSportsGameCategoriesFailure())
  }
}
function * placeBetWorker (action) {
  const { quickBetLoading } = yield select((state) => state?.sports)
  const { data: formData, onSuccess } = action.payload

  const quickLoadingId = `${formData?.bets?.[0]?.providerMatchId}${formData?.bets?.[0]?.providerOutcomeId}`

  try {
    const { data } = yield placeBet(formData)
    if (data?.data?.placed) {
      yield put(placeBetComplete())
      const quickBetArr = quickBetLoading?.map((item) => item !== quickLoadingId)
      yield put(quickBetLoadingStatus(quickBetArr))
      onSuccess()
      yield toast(t('betPlacedSuccess'), 'betsuccess')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    const quickBetArr = quickBetLoading?.map((item) => item !== quickLoadingId)
    yield put(quickBetLoadingStatus(quickBetArr))
    yield put(placeBetComplete())
  }
}

function * getSportsWorker (action) {
  try {
    const { data } = yield getSports(action.payload)
    yield put(getSportsComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getSportsFailure())
  }
}

function * getLeagueWorker (action) {
  try {
    const { data } = yield getLeagues(action.payload)
    yield put(getLeagueComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getLeagueFailure())
  }
}

function * getEventWorker (action) {
  const { setUpcomingCustomEvents } = action.payload
  try {
    const { data } = yield getEvents(action.payload.data)
    yield put(getEventComplete(data?.data))
    yield put(setSocketUpcomingEventData(data?.data?.rows || []))
    yield setUpcomingCustomEvents(data?.data)
  } catch (e) {
    // yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getEventFailure())
  }
}
function * getLiveEventWorker (action) {
  const { setLiveCustomEvents } = action.payload
  try {
    const { data } = yield getEvents(action.payload.data)
    yield put(getLiveEventComplete(data?.data))
    yield put(setSocketLiveEventData(data?.data?.rows || []))
    yield setLiveCustomEvents(data?.data)
  } catch (e) {
    // yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getLiveEventFailure())
  }
}

function * getMarketCategoryWorker (action) {
  try {
    const { data } = yield getMarketCategories(action.payload)
    yield put(getMarketCategoryComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getMarketCategoryFailure())
  }
}

function * getTopLeaguesWorker (action) {
  try {
    const { data } = yield getTopLeagues(action.payload)
    yield put(getTopLeaguesComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTopLeaguesFailure())
  }
}

function * getTopEventsWorker (action) {
  const { setTopCustomEvents } = action.payload
  try {
    const { data } = yield getTopEvents(action.payload.data)
    yield put(getTopEventsComplete(data?.data))
    yield put(setSocketTopEventData(data?.data?.rows || []))
    yield setTopCustomEvents(data?.data)
  } catch (e) {
    // yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTopEventsFailure())
  }
}

function * getResultsWorker (action) {
  try {
    const { data } = yield getResults(action.payload)
    yield put(getResultsComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getResultsFailure())
  }
}

function * getSingleEventWorker (action) {
  const { matchData, stateData, providerMatchId, setSingleCustomEvents } = action.payload
  try {
    const { data } = yield getSingleEvent({ ...matchData })
    const eventData = data?.data?.eventData
    yield put(getSingleEventComplete({ eventData: { ...eventData, ...stateData } }))
    yield put(setSocketDetailsPageSingleEvents([{ ...eventData, ...stateData }]))
    yield setSingleCustomEvents({ eventData: { ...eventData, ...stateData } })
    yield put(setSocketUpcomingFavoriteEvents([]))
    yield put(setSocketLiveFavoriteEvents([]))
    yield put(setSocketUpcomingEventData([]))
    yield put(setSocketLiveEventData([]))
    yield put(setSocketTopEventData([]))
    yield put(getHotCombosComplete({}))
    if (eventData?.customBet) {
      yield put(getCustomEventsStart({ providerMatchId, pageNo: 1, limit: 10 }))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getSingleEventFailure())
  }
}

function * getLiveFavWorker (action) {
  const { dispatchEvent } = action.payload
  try {
    const { data } = yield getFavorites(action.payload.data)
    yield put(setSocketLiveFavoriteEvents(data?.data?.rows))
    yield put(getLiveFavComplete(data?.data))
    yield dispatchEvent(data?.data)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getLiveFavFailure())
  }
}

function * getUpcomingFavWorker (action) {
  const { dispatchEvent } = action.payload
  try {
    const { data } = yield getFavorites(action.payload.data)
    yield put(setSocketUpcomingFavoriteEvents(data?.data?.rows))
    yield put(getUpcomingFavComplete(data?.data))
    yield dispatchEvent(data?.data)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getUpcomingFavFailure())
  }
}

function * getCountriesWorker (action) {
  try {
    const { data } = yield getCountries(action.payload)
    yield put(getCountriesComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getCountriesFailure())
  }
}

function * toggleFavoriteWorker (action) {
  try {
    const { data } = yield addFavorites(action.payload.data)
    yield put(toggleFavoriteSuccess(data?.data))
    if (data?.data.isFavorite) yield toast(t('favoriteAdded'), 'success')
    else yield toast(t('favoriteRemoved'), 'success')
    if (action?.payload?.data?.onSuccess) {
      yield call(action?.payload?.data?.onSuccess)
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(toggleFavoriteFailure())
  }
}

function * getEventMarketsWorker (action) {
  const { setMarketDetailsCustomEvents } = action.payload
  try {
    const { data } = yield getMarkets(action.payload.data)
    yield put(getEventMarketsComplete(data?.data))
    const merketData = data?.data?.rows?.map((item) => {
      const outcomes = [...item.outcome]
      const outcomeSort = outcomes.sort((a, b) => {
        if (a?.providerOutcomeId?.includes('variant') || b?.providerOutcomeId?.includes('variant')) {
          return parseFloat(a.providerOutcomeId) - parseFloat(b.providerOutcomeId)
        } else if (a?.providerOutcomeId?.includes('=') || b?.providerOutcomeId?.includes('=')) {
          const aData = a.providerOutcomeId.split('=')?.[1]
          const bData = b.providerOutcomeId.split('=')?.[1]
          return parseFloat(aData) - parseFloat(bData)
        } else {
          return parseFloat(a.providerOutcomeId) - parseFloat(b.providerOutcomeId)
        }
      })
      return { ...item, outcome: outcomeSort }
    })
    yield setMarketDetailsCustomEvents({ ...data?.data, rows: merketData })
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getEventMarketsFailure())
  }
}

function * getMyBetsWorker (action) {
  try {
    const { data } = yield getAllBets(action.payload)
    yield put(getMyBetsComplete(data.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getMyBetsFailure())
  }
}

function * getBetDetailsWorker (action) {
  try {
    const { data } = yield getBetDetails(action.payload)
    yield put(getBetDetailsComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getBetDetailsFailure())
  }
}

function * getAltStakeBetDetailsWorker (action) {
  try {
    const { notifications } = yield select(state => state.user)
    const { data } = yield getBetDetails(action.payload)
    yield put(altStakeBetSlipSuccess(data?.data?.betList?.[0]))
    yield put(setAltStakePopupOpen(true))
    yield put(getNotificationsSuccess({ data: notifications?.data?.filter((item) => item?.notificationId !== action.payload?.betslipId) }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(altStakeBetSlipFailure())
  }
}

function * getCashoutAmountWorker (action) {
  try {
    const { data } = yield getCashoutAmount(action.payload)
    yield put(getCashoutAmountComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getCashoutAmountFailure())
  }
}

function * processCashoutWorker (action) {
  try {
    const { myBets } = yield select((state) => state?.sports)
    const { data } = yield processCashout(action.payload)
    yield put(processCashoutComplete())
    const newMyBats = myBets?.betSlipList?.map((item) => {
      if (item?.betslipId === action?.payload?.betslipId) {
        return { ...item, settlementStatus: 'cashoutInprogress' }
      } else {
        return { ...item }
      }
    })
    yield put(getMyBetsComplete({ betSlipList: newMyBats }))
    yield toast(t('cashoutMessage'), 'betsuccess')
    return data
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(processCashoutFailure())
  }
}

function * alternativeStakeWorker (action) {
  const { myBets } = yield select((state) => state?.sports)
  const { data, onClose, isNotification } = action.payload
  try {
    const res = yield alternativeStake({ ...data })
    yield put(alternativeStakeComplete())
    yield toast(res?.data?.data?.message, 'success')
    if (!isNotification) {
      const newMyBats = myBets?.betSlipList.map((item) => {
        if (item?.betslipId === data.betslipId) {
          const newObj = { ...item, isSuggestion: false }
          // delete newObj.suggestion
          return { ...newObj }
        } else {
          return { ...item }
        }
      })
      yield put(getMyBetsComplete({ betSlipList: newMyBats }))
    }
    onClose(false)
    return res
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(alternativeStakeFailure())
  }
}

function * reofferBetWorker (action) {
  const { myBets } = yield select((state) => state?.sports)
  const { data, onClose, isNotification } = action.payload
  try {
    const res = yield betReoffer({ ...data })
    yield toast(res?.data?.data?.message, 'success')
    yield put(reofferBetComplete())
    if (!isNotification) {
      const newMyBats = myBets?.betSlipList.map((item) => {
        if (item?.betslipId === action.payload.betslipId) {
          const newObj = { ...item }
          delete newObj.suggestion
          return { ...newObj }
        } else {
          return { ...item }
        }
      })
      yield put(getMyBetsComplete({ betSlipList: newMyBats }))
    }
    onClose(false)
    return res
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(reofferBetFailure())
  }
}

function * calculateOdsWorker (action) {
  const { matchData, currentId, setCustomBetData } = action.payload
  const { customEvents } = yield select((state) => state?.sports)
  try {
    const { data } = yield calculateOds({ selections: matchData })
    if (data?.data.status) {
      yield put(getCalculatesOdsComplete(data?.data?.calculatedOdd))
    } else {
      yield toast(data?.data?.message, 'betfail')
      setCustomBetData((oldValue) => {
        return oldValue.filter((item) => item?.market?.matchMarketId !== currentId)
      })
      const customEventData = customEvents?.resultantCustomMarkets?.map((item) => {
        if (item?.matchMarketId === currentId) {
          return { ...item, isMarketLocked: true }
        } else {
          return { ...item }
        }
      })
      if (customEventData.length > 0) {
        yield put(getCustomEventsComplete({ ...customEvents, resultantCustomMarkets: customEventData }))
      }

      yield put(getCalculatesOdsFailure())
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getCalculatesOdsFailure())
  }
}

function * customEventWorker (action) {
  const matchData = action.payload
  try {
    const { data } = yield getCustomEvents({ ...matchData })
    const customData = data?.data?.resultantCustomMarkets?.map((item) => ({ ...item, MasterMarkets: [item?.MasterMarkets] }))
    yield put(getCustomEventsComplete({ ...data?.data, resultantCustomMarkets: customData }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getCustomEventsFailure())
  }
}

function * getHotCombosWorker (action) {
  const { setHotComboEvents } = action.payload
  try {
    const { data } = yield getHotCombos()

    yield put(getHotCombosComplete(data?.data))
    yield setHotComboEvents(data?.data)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getHotCombosFailure())
  }
}
