import { createSlice } from '@reduxjs/toolkit'

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    loading: false,
    betHistory: null,
    withdrawalHistory: null,
    userTransactions: {},
    transactionsLoading: false,
    walletActionLoading: false,
    walletTransactions: {},
    vaultTransactions: {},
    vaultTransactionsLoading: false,
    walletTransactionsLoading: false,
    vaultActionLoading: false,
    bonusTransactionsLoading: false,
    bonusTransactions: {}
  },
  reducers: {
    getBonusTransactionsStart: (state) => ({
      ...state,
      bonusTransactionsLoading: true
    }),
    getBonusTransactionsSuccess: (state, { payload }) => ({
      ...state,
      bonusTransactionsLoading: false,
      bonusTransactions: payload
    }),
    getBonusTransactionsFailure: (state) => ({
      ...state,
      bonusTransactionsLoading: false
    }),
    getWalletTransactionsStart: (state) => ({
      ...state,
      walletTransactionsLoading: true
    }),
    getWalletTransactionsSuccess: (state, { payload }) => ({
      ...state,
      walletTransactionsLoading: false,
      walletTransactions: payload
    }),
    getWalletTransactionsFailure: (state) => ({
      ...state,
      walletTransactionsLoading: false
    }),
    getVaultTransactionsStart: (state) => ({
      ...state,
      vaultTransactionsLoading: true
    }),
    getVaultTransactionsSuccess: (state, { payload }) => ({
      ...state,
      vaultTransactionsLoading: false,
      vaultTransactions: payload
    }),
    getVaultTransactionsFailure: (state) => ({
      ...state,
      vaultTransactionsLoading: false
    }),
    getUserBetHistoryStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserBetHistorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      betHistory: payload
    }),
    getUserBetHistoryFailure: (state) => ({
      ...state,
      loading: false
    }),
    getUserIdAndSessionIdStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserIdAndSessionIdSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userPaymentCredential: payload
    }),
    getUserIdAndSessionIdFailure: (state) => ({
      ...state,
      loading: false
    }),
    clearUserIdAndSessionId: (state) => ({
      ...state,
      userPaymentCredential: null
    }),
    getWithdrawalHistoryStart: (state) => ({
      ...state,
      loading: true,
      withdrawalHistory: null
    }),
    getWithdrawalHistorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      withdrawalHistory: payload
    }),
    getWithdrawalHistoryFailure: (state) => ({
      ...state,
      loading: false
    }),
    cancelWithdrawRequestStart: (state) => ({
      ...state,
      loading: true
    }),
    cancelWithdrawRequestComplete: (state) => ({
      ...state,
      loading: false
    }),
    getUserTransactionsStart: (state) => ({
      ...state,
      transactionsLoading: true
    }),
    getUserTransactionsSuccess: (state, { payload }) => ({
      ...state,
      transactionsLoading: false,
      userTransactions: payload
    }),
    getUserTransactionsFailure: (state) => ({
      ...state,
      transactionsLoading: false
    }),
    setWalletActionStart: (state) => ({
      ...state,
      walletActionLoading: true
    }),
    setWalletActionSuccess: (state, { payload }) => ({
      ...state,
      walletActionLoading: false
    }),
    setWalletActionFailure: (state) => ({
      ...state,
      walletActionLoading: false
    }),
    setVaultActionStart: (state) => ({
      ...state,
      vaultActionLoading: true
    }),
    setVaultActionSuccess: (state, { payload }) => ({
      ...state,
      vaultActionLoading: false
    }),
    setVaultActionFailure: (state) => ({
      ...state,
      vaultActionLoading: false
    })
  }
})

export default transactionsSlice.reducer

export const {
  getBonusTransactionsStart,
  getBonusTransactionsSuccess,
  getBonusTransactionsFailure,
  getUserBetHistoryStart,
  getUserBetHistorySuccess,
  getUserBetHistoryFailure,
  getUserIdAndSessionIdStart,
  getUserIdAndSessionIdSuccess,
  getUserIdAndSessionIdFailure,
  clearUserIdAndSessionId,
  getWithdrawalHistoryStart,
  getWithdrawalHistorySuccess,
  getWithdrawalHistoryFailure,
  cancelWithdrawRequestStart,
  cancelWithdrawRequestComplete,
  getUserTransactionsStart,
  getUserTransactionsSuccess,
  getUserTransactionsFailure,
  setWalletActionStart,
  setWalletActionSuccess,
  setWalletActionFailure,
  getWalletTransactionsStart,
  getWalletTransactionsSuccess,
  getWalletTransactionsFailure,
  setVaultActionStart,
  setVaultActionSuccess,
  setVaultActionFailure,
  getVaultTransactionsStart,
  getVaultTransactionsSuccess,
  getVaultTransactionsFailure
} = transactionsSlice.actions
